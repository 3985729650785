export default `
allTreatments {
  treatmentlist {
    _key
    name
    featured
    description
    price
  }
}`;
