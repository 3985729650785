<template>
  <footer>
    <div class="footer-wrap">
      <div class="company-info">
        <span class="name">{{ company.name }}</span>
        <span class="address">
          <span class="sr-only">Adresse: </span>{{ company.address }}
        </span>
        <span class="postal-code">
          {{ company.postalCode }} {{ company.postalPlace }}
        </span>
        <a class="phone" :href="`tel:${company.phone}`">
          <span class="sr-only">Telefonnummer: </span>{{ company.phone }}
        </a>
        <a class="email" :href="`mailto:${company.email}`">
          <span class="sr-only">Epost: </span>{{ company.email }}
        </a>
        <span class="organisation-number">
          <span class="sr-only">
            Organiasasjonsnummer {{ company.organisationNumber }}
          </span>
          <span aria-hidden="true">Orgnr {{ company.organisationNumber }}</span>
        </span>
      </div>
      <div class="footer-social">
        <a
          :href="company.facebook"
          class="footer-social-link"
          target="_blank"
          rel="nofollow noopener"
        >
          <FacebookIcon class="social-icon" />
          <span class="sr-only">Besøk Oker på facebook</span>
        </a>
        <a
          :href="company.instagram"
          class="footer-social-link"
          target="_blank"
          rel="nofollow noopener"
        >
          <InstagramIcon class="social-icon" />
          <span class="sr-only">Besøk Oker på Instagram</span>
        </a>
      </div>
    </div>
    <MadeBy />
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import company from "@/data/company";
import FacebookIcon from "@/assets/icons/facebook.svg";
import InstagramIcon from "@/assets/icons/instagram.svg";
import MadeBy from "@/components/MadeBy.vue";

export default defineComponent({
  name: "Footer",
  setup() {
    return { company };
  },
  components: {
    InstagramIcon,
    FacebookIcon,
    MadeBy,
  },
});
</script>

<style lang="scss" scoped>
footer {
  background-color: $oker-grey-1;
  position: relative;
  line-height: 1.2;
  @include slanted;
}

.footer-wrap {
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
}

.name {
  font-weight: 500;
}

a {
  color: #333;
}

.company-info * {
  display: block;
  line-height: 1.3;
}

.footer-social {
  display: flex;
  margin-left: auto;
  align-items: flex-start;
}

.footer-social-link {
  padding: 10px;

  &:hover .social-icon {
    opacity: 0.9;
  }
}

.social-icon {
  width: 25px;
  color: #000;
  opacity: 0.6;
}
</style>
