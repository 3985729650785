
import { defineComponent, computed } from "vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "Button",
  props: {
    primary: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    to: {
      type: String,
      required: false,
    },
  },
  setup(props, { slots }) {
    const classes = computed(() => {
      return {
        button: true,
        "button--primary": props.primary,
        "button--icon": slots.icon,
      };
    });

    return { classes };
  },
  components: {
    RouterLink,
  },
});
