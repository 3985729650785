
import { defineComponent, inject } from "vue";
import { Hairdresser as HairdresserType } from "@/types/hairdresser";
import Block from "@/components/Block.vue";
import Hairdresser from "@/components/Hairdresser.vue";

interface Hairdressers {
  value: HairdresserType[];
}

export default defineComponent({
  setup() {
    const isLoading = inject("isLoading");
    const hairdressers: Hairdressers = inject("hairdressers") || {
      value: [],
    };
    return {
      isLoading,
      hairdressers,
    };
  },
  components: {
    Block,
    Hairdresser,
  },
});
