
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import CalendarIcon from "@/assets/icons/calendar.svg";
import company from "@/data/company";

export default defineComponent({
  name: "CallButton",
  props: {
    primary: {
      type: Boolean,
      default: true,
    },
    cta: {
      type: String,
      default: "Bestill time",
    },
  },
  setup() {
    return { company };
  },
  components: {
    CalendarIcon,
    Button,
  },
});
