<template>
  <header class="header">
    <div class="header-wrapper">
      <router-link to="/" class="header-logo-link">
        <h1 class="header-page-title">
          <Logo class="header-logo" inverted />
          <span class="sr-only">Oker Frisør</span>
        </h1>
        <span class="sr-only">Til forsiden</span>
      </router-link>
      <Menu class="header-menu" />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/components/Logo.vue";
import Menu from "@/components/Menu.vue";

export default defineComponent({
  name: "Header",
  components: { Logo, Menu },
});
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-wrap: wrap;
}

.header-wrapper {
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.header-page-title {
  display: inline-block;
  margin: 0;
}

.header-logo {
  transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border-radius: 500px;

  &:hover,
  .header-logo-link:focus & {
    box-shadow: 0 0 5px 0 rgba($oker-black, 0.2);
    background: rgba($oker-white, 0.2);
  }
}

.header-logo-link {
  outline: none;
  border-radius: 500px;
  padding: 2px;

  &:focus-visible {
    @include focus-indicator;
  }
}

.header-menu {
  margin-top: 30px;
  @media (min-width: 600px) {
    margin-left: auto;
    margin-top: 0;
  }
}
</style>
