const company = {
  name: "Oker AS",
  address: "Stasjonsveien 75",
  area: "Nittedal Stasjon",
  postalCode: "1482",
  postalPlace: "Nittedal",
  phone: "902 21 500",
  email: "hei@okerhar.no",
  organisationNumber: "917 463 654",
  instagram: "https://www.instagram.com/oker_har",
  facebook: "https://www.facebook.com/okerhar/",
};

export default company;
