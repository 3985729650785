<template>
  <Seo
    title="Oker - Frisørsalongen på Nittedal stasjon"
    canonical="https://www.okerhar.no"
    description="Din lokale frisør i Nittedal. Hos oss er du garantert godt håndverk og god kvalitet. Velkommen til oss, på Nittedal Stasjon."
  />
  <Page>
    <Intro color="blue" title="Din lokale frisør i Nittedal" :intro="introText">
      <BookOnlineButton :primary="false" class="call-button" />
    </Intro>
    <section class="info-section">
      <div class="two-column">
        <Block title="Våre behandlinger">
          <Treatments :shortlist="true" />
          <router-link class="all-treatments-link" to="/behandlinger">
            Vis alle behandlinger
          </router-link>
        </Block>
        <Block title="Åpningstider">
          <OpeningHours />
        </Block>
      </div>
      <div class="block-actions">
        <BookOnlineButton />
      </div>
    </section>
    <section class="location-section">
      <Block title="Her finner du oss">
        <div class="contact-details">
          <span>{{ company.area }}</span>
          <span>{{ company.address }}</span>
          <span>{{ company.postalCode }} {{ company.postalPlace }}</span>
        </div>
        <Map />
      </Block>
    </section>
    <section class="brand-section">
      <Block title="Merker">
        <Brands />
      </Block>
    </section>
  </Page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OpeningHours from "@/components/OpeningHoursList.vue";
import Treatments from "@/components/TreatmentsList.vue";
import Block from "@/components/Block.vue";
import Brands from "@/components/BrandList.vue";
import Map from "@/components/Map.vue";
import Seo from "@/components/Seo.vue";
import BookOnlineButton from "@/components/BookOnlineButton.vue";
import company from "@/data/company";
import Page from "@/components/Page.vue";
import Intro from "@/components/Intro.vue";

export default defineComponent({
  name: "FrontPage",
  setup() {
    const introText = `Hos oss er du garantert godt håndverk og
          god kvalitet. Vi ønsker at du som kunde skal få en god opplevelse og
          veiledning. Vi tilbyr alle behandlinger innenfor vårt fag. Velkommen
          til oss, på Nittedal Stasjon.`;

    return { company, introText };
  },
  components: {
    Page,
    Intro,
    Brands,
    BookOnlineButton,
    Treatments,
    OpeningHours,
    Block,
    Map,
    Seo,
  },
});
</script>
<style lang="scss" scoped>
$breakpoint: 650px;

section {
  padding: 80px 0;
}

.info-block {
  background: $oker-grey-1;
}

.block-actions {
  display: flex;
  justify-content: center;

  @media screen and (max-width: $breakpoint) {
    flex-direction: column;

    .all-treatments-button {
      display: none;
    }

    .button + .button {
      margin: 0;
    }
    .button.button--primary {
      margin: 0 auto;
      width: 100%;
    }
  }
}

.block .all-treatments-button {
  display: none;

  @media screen and (max-width: $breakpoint) {
    display: block;
  }
}

.all-treatments-link {
  font-weight: 500;
  color: $oker-blue;
}

.location-section {
  background: #fff;
  @include slanted;
  padding-bottom: 0;
  padding-left: 0px;
  padding-right: 0px;
}

.info-section {
  background: $oker-grey-1;

  @include slanted;
}

.brand-section {
  background: $oker-grey-2;
}

.two-column {
  margin: 0 auto 40px auto;
  display: flex;
  max-width: 800px;
  width: 100%;
  justify-content: space-around;

  @media screen and (max-width: $breakpoint) {
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
  }

  .block {
    margin: 0 30px;
    width: 100%;
    max-width: 350px;
    margin-bottom: 40px;
    @media screen and (min-width: $breakpoint) {
      max-width: 250px;
      margin-bottom: 0;
    }
  }
}

.contact-details {
  text-align: center;
  margin-bottom: 60px;

  span {
    display: block;
  }
}

.call-button {
  margin: 0 auto;
}
</style>
