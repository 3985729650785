<template>
  <li :class="classes">
    <template v-if="expandable">
      <details>
        <summary>
          <span class="treatment-name">
            {{ treatment.name }}
            <CaretDownIcon class="caret-down-icon" />
          </span>
          <span class="price">
            {{ price }}
            <span class="sr-only" v-if="treatment.price"> kroner</span>
            <span class="sr-only" v-if="!treatment.price"> for pristilbud</span>
          </span>
        </summary>
        <p class="description">
          {{ treatment.description }}
        </p>
      </details>
    </template>
    <template v-if="!expandable">
      <span class="treatment-name">{{ treatment.name }}</span>
      <span class="price">
        {{ price }}
        <span class="sr-only" v-if="treatment.price"> kroner</span>
        <span class="sr-only" v-if="!treatment.price"> for pristilbud</span>
      </span>
    </template>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, reactive } from "vue";
import { Treatment } from "@/types/treatment";
import CaretDownIcon from "@/assets/icons/caret-down.svg";
export default defineComponent({
  name: "Treatment",
  props: {
    treatment: {
      type: Object as PropType<Treatment>,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const state = reactive({
      expanded: false,
    });

    const toggleLabel = computed(() =>
      state.expanded ? "Skjul beskrivelse" : "Vis beskrivelse"
    );

    const toggleDescription = () => {
      if (!props.expandable) return;

      state.expanded = !state.expanded;
    };

    const price = computed(() => {
      if (props.treatment.price) {
        return `fra ${props.treatment.price},-`;
      } else {
        return `Ta kontakt`;
      }
    });

    const classes = computed(() => {
      return {
        treatment: true,
        "treatment--expanded": state.expanded,
        "treatment-expandable": props.expandable,
      };
    });

    return { classes, state, price, toggleDescription, toggleLabel };
  },
  components: {
    CaretDownIcon,
  },
});
</script>

<style lang="scss" scoped>
.treatment-name {
  margin: 0;
  font-size: 16px;
  color: $oker-grey;
  font-weight: 500;
  display: inline;

  .treatment-expandable &:hover {
    color: $oker-blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

summary {
  cursor: pointer;
  display: block;

  details[open] & {
    margin-bottom: 10px;
  }
}

details summary::-webkit-details-marker,
details summary::marker {
  display: none;
}

.price {
  float: right;
  order: 3;
}

details {
  &:focus {
    @include focus-indicator;
  }

  &[open] {
    padding-left: 10px;
    margin-right: -14px;
    transform: translateX(-14px);
    border-left: 4px solid $oker-yellow;
  }
}

.treatment {
  list-style: none;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.caret-down-icon {
  width: 25px;
  transition: transform 0.1s ease-in-out;

  details[open] & {
    transform: scaleY(-1);
  }
}

.toggle {
  background: none;
  border: none;
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 1.6rem;
  order: 2;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-radius: 3px;
  }
}

.description {
  width: 100%;
  margin: 0 100px 0 0;
  line-height: 1.3;
}
</style>
