<template>
  <ul class="treatments" v-if="!isLoading">
    <Treatment
      v-for="treatment in treatmentList"
      :key="treatment.id"
      :treatment="treatment"
      :expandable="!shortlist"
    />
  </ul>
</template>

<script lang="ts">
import { defineComponent, computed, inject } from "vue";
import Treatment from "@/components/Treatment.vue";
import { Treatment as TreatmentType } from "@/types/treatment";

interface treatments {
  value: TreatmentType[];
}

export default defineComponent({
  name: "TreatmentsList",
  props: {
    shortlist: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isLoading = inject("isLoading");
    const treatments: treatments = inject("treatments") || { value: [] };

    const treatmentList = computed(() => {
      if (props.shortlist) {
        return treatments.value.filter(
          (treatment) => treatment.frontpage === true
        );
      } else {
        return treatments.value;
      }
    });

    return {
      isLoading,
      treatmentList,
    };
  },
  components: {
    Treatment,
  },
});
</script>

<style lang="scss" scoped>
.treatments {
  padding-left: 0;
}
</style>
