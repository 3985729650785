<template>
  <li class="hairdresser">
    <div class="hairdresser-biography">
      <h3>{{ hairdresser.name }}</h3>
      <p>{{ hairdresser.biography }}</p>
    </div>
    <img
      class="hairdresser-portrait"
      :src="`${hairdresser.portrait}?w=240`"
      :alt="`Portrett av ${hairdresser.name}`"
      loading="lazy"
    />
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Hairdresser } from "@/types/hairdresser";

export default defineComponent({
  name: "Hairdresser",
  props: {
    hairdresser: {
      type: Object as PropType<Hairdresser>,
    },
  },
});
</script>

<style lang="scss" scoped>
.hairdresser {
  max-width: 800px;
  margin: 0 auto 40px auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
}

.hairdresser-biography {
  margin-left: 40px;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
}

.hairdresser-portrait {
  border-radius: 3px;
  width: 30%;

  @media screen and (max-width: 500px) {
    width: 50%;
  }
}
</style>
