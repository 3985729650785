<template>
  <li :class="classes">
    <span>{{ openingHour.day }}</span>
    <span v-if="!openingHour.closed">
      {{ openingHour.from }} <span class="sr-only"> til </span>-
      {{ openingHour.to }}
    </span>
    <span v-else>Stengt</span>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { OpeningHour } from "@/types/openinghours";

export default defineComponent({
  name: "OpeningHour",
  props: {
    today: {
      type: Boolean,
      required: true,
    },
    openingHour: {
      type: Object as PropType<OpeningHour>,
      required: true,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        "opening-hours": true,
        "opening-hours--today": props.today,
      };
    });

    return { classes };
  },
});
</script>

<style lang="scss" scoped>
.opening-hours {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &--today {
    font-weight: 500;
  }
}
</style>
