
import { defineComponent, PropType } from "vue";
import { Brand } from "@/types/brand";

export default defineComponent({
  name: "Brand",
  props: {
    brand: {
      type: Object as PropType<Brand>,
      required: true,
    },
  },
});
