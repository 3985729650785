
import { defineComponent } from "vue";
import BookOnlineButton from "@/components/BookOnlineButton.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Bli bedre kjent med oss",
    },
    cta: {
      type: String,
      default: "Bestill time",
    },
  },
  components: {
    BookOnlineButton,
  },
});
