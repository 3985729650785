export default `
allBrands {
  name
  website
  logo {
    asset: asset {
      url
    }
  }
}`;
