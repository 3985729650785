<template>
  <div :class="classes">
    <Block :title="title" inverted>
      <p class="intro-paragraph">{{ intro }}</p>
      <slot />
    </Block>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import Block from "@/components/Block.vue";

export default defineComponent({
  name: "Intro",
  props: {
    color: {
      type: String as PropType<"blue" | "yellow" | "brown">,
      default: "yellow",
    },
    intro: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      intro: true,
      [`intro--${props.color}`]: props.color,
    }));

    return { classes };
  },
  components: {
    Block,
  },
});
</script>

<style lang="scss" scoped>
.intro {
  display: flex;
  flex-wrap: wrap;
  padding: 120px 20px 80px 20px;

  @media (max-width: 600px) {
    padding-top: 160px;
  }

  &--yellow {
    background-color: $oker-yellow;
    background-image: url("/header-bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1000px;
    background-position-y: 0px;
    background-size: cover;
  }

  &--blue {
    background-color: $oker-blue;
    background-color: #313c52;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%2355627e' fill-opacity='0.16'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &--brown {
    background-color: $oker-brown;
  }

  :deep(h2) {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .block {
    width: 100%;
    margin: 40px;
    text-align: center;
  }

  .intro-paragraph {
    max-width: 600px;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  :deep(.button) {
    margin: 0 auto;
    margin-top: 40px;
    border-color: #fff;
  }
}
</style>
