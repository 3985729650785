
import { defineComponent, computed, PropType } from "vue";
import Block from "@/components/Block.vue";

export default defineComponent({
  name: "Intro",
  props: {
    color: {
      type: String as PropType<"blue" | "yellow" | "brown">,
      default: "yellow",
    },
    intro: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      intro: true,
      [`intro--${props.color}`]: props.color,
    }));

    return { classes };
  },
  components: {
    Block,
  },
});
