import { Brand, BrandAPI } from "@/types/brand";
import { Hairdresser, HairdresserAPI } from "@/types/hairdresser";
import { OpeningHour, OpeningHourAPI } from "@/types/openinghours";
import { Treatment, TreatmentAPI } from "@/types/treatment";

export const transformBrands = (brands: BrandAPI[]): Brand[] => {
  return brands.map((brand) => {
    const {
      logo: {
        asset: { url },
      },
      website,
      name,
    } = brand;

    return {
      name,
      logo: url,
      url: website,
    };
  });
};

export const transformHairdressers = (
  hairdressers: HairdresserAPI[]
): Hairdresser[] => {
  return hairdressers.map((hairdresser) => {
    const {
      _key,
      name,
      description,
      portrait: {
        asset: { url },
      },
    } = hairdresser;

    return {
      id: _key,
      biography: description,
      name,
      portrait: url,
    };
  });
};

export const transformOpeningHours = (
  openingHours: OpeningHourAPI[]
): OpeningHour[] => {
  return openingHours.map((openingHour) => {
    const { opensAt, closesAt, open, label, index } = openingHour;

    return {
      id: index,
      day: label,
      from: opensAt,
      to: closesAt,
      closed: !open,
    };
  });
};

export const transformTreatments = (
  treatments: TreatmentAPI[]
): Treatment[] => {
  return treatments.map((treatment) => {
    const { description, name, price, featured } = treatment;

    return {
      id: 1,
      description,
      name,
      price,
      frontpage: featured,
    };
  });
};
