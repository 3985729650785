<template>
  <div :class="classes">
    <h2 class="block-title" v-if="title">{{ title }}</h2>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Block",
  props: {
    title: {
      type: String,
      required: false,
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      block: true,
      "block--inverted": props.inverted,
    }));

    return {
      classes,
    };
  },
});
</script>

<style lang="scss" scoped>
.block {
  color: $oker-grey;

  &--inverted {
    color: $oker-white;
  }
}

.block-title {
  font-size: 2.1rem;
  text-transform: uppercase;
  text-align: center;
}
</style>
