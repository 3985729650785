<template>
  <teleport to="head">
    <title>{{ title }}</title>
    <meta name="description" :content="description" />
    <link rel="canonical" :href="canonical" />
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Seo",
  props: {
    title: {
      type: String,
      required: true,
    },
    canonical: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
});
</script>
