<template>
  <nav class="main-menu">
    <ul class="main-menu-links">
      <li>
        <router-link class="main-menu-link" to="/behandlinger">
          Behandlinger
        </router-link>
      </li>
      <li>
        <router-link class="main-menu-link" to="/om-salongen">
          Om oss
        </router-link>
      </li>
      <li>
        <router-link class="main-menu-button" to="/bestill-time">
          Bestill time
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainMenu",
});
</script>

<style lang="scss" scoped>
.main-menu-links {
  margin: 0;
  margin-left: -10px;
  list-style: none;
  padding: 0;
  display: flex;
}

.main-menu-link {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  padding: 5px 8px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba($oker-black, 0.3);

  &:visited,
  &:link {
    color: $oker-white;
  }

  &::after {
    width: 0%;
    transition: width 0.1s ease-in-out;
    position: absolute;
    left: 3px;
    bottom: -1px;
    content: "";
  }

  &:focus-visible {
    @include focus-indicator;
    border-radius: 3px;
  }

  &.router-link-exact-active::after,
  &:hover::after {
    width: calc(100% - 16px);
    margin: 0 8px;

    border-bottom: 2px solid $oker-white;
  }
}

.main-menu-button {
  background: $oker-white;
  padding: 5px 8px;
  margin-left: 10px;
  border-radius: 3px;
  font-weight: 600;
  color: $oker-blue;
  white-space: nowrap;
  text-decoration: none;

  &:hover,
  &:focus {
    background: $oker-yellow;
  }
}

.router-link-exact-active {
  font-weight: 600;
}
</style>
