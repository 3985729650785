<template>
  <Button :primary="primary" type="router-link" to="/bestill-time">
    <template #icon>
      <CalendarIcon class="icon" />
    </template>
    {{ cta }}
  </Button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import CalendarIcon from "@/assets/icons/calendar.svg";
import company from "@/data/company";

export default defineComponent({
  name: "CallButton",
  props: {
    primary: {
      type: Boolean,
      default: true,
    },
    cta: {
      type: String,
      default: "Bestill time",
    },
  },
  setup() {
    return { company };
  },
  components: {
    CalendarIcon,
    Button,
  },
});
</script>
<style lang="scss" scoped>
.icon {
  margin: -5px;
  height: 30px;
}
</style>
