
import { defineComponent, PropType, computed, reactive } from "vue";
import { Treatment } from "@/types/treatment";
import CaretDownIcon from "@/assets/icons/caret-down.svg";
export default defineComponent({
  name: "Treatment",
  props: {
    treatment: {
      type: Object as PropType<Treatment>,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const state = reactive({
      expanded: false,
    });

    const toggleLabel = computed(() =>
      state.expanded ? "Skjul beskrivelse" : "Vis beskrivelse"
    );

    const toggleDescription = () => {
      if (!props.expandable) return;

      state.expanded = !state.expanded;
    };

    const price = computed(() => {
      if (props.treatment.price) {
        return `fra ${props.treatment.price},-`;
      } else {
        return `Ta kontakt`;
      }
    });

    const classes = computed(() => {
      return {
        treatment: true,
        "treatment--expanded": state.expanded,
        "treatment-expandable": props.expandable,
      };
    });

    return { classes, state, price, toggleDescription, toggleLabel };
  },
  components: {
    CaretDownIcon,
  },
});
