
import { defineComponent } from "vue";
import InfoIcon from "@/assets/icons/info.svg";

export default defineComponent({
  props: {
    content: {
      type: String,
      required: false,
    },
  },
  components: {
    InfoIcon,
  },
});
