
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Block",
  props: {
    title: {
      type: String,
      required: false,
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      block: true,
      "block--inverted": props.inverted,
    }));

    return {
      classes,
    };
  },
});
