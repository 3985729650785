
import { defineComponent } from "vue";
import Page from "@/components/Page.vue";
import Intro from "@/components/Intro.vue";
import Seo from "@/components/Seo.vue";
import HairdressersList from "@/components/HairdressersList.vue";
import CallToAction from "@/components/CallToAction.vue";

export default defineComponent({
  name: "AboutPage",
  setup() {
    const introText = `Din lokale frisør i Nittedal. Hos oss er du garantert godt håndverk og
          god kvalitet. Vi ønsker at du som kunde skal få en god opplevelse og
          veiledning. Vi tilbyr alle behandlinger innenfor vårt fag. Velkommen
          til oss, på Nittedal Stasjon.`;

    return { introText };
  },
  components: {
    CallToAction,
    Intro,
    Page,
    HairdressersList,
    Seo,
  },
});
