<template>
  <a href="#maincontent" class="sr-only focus:not-sr-only jump-to-content">
    Hopp til hovedinnhold
  </a>
  <div class="page-wrapper">
    <Header />
    <main id="maincontent">
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Page",
  components: {
    Header,
    Footer,
  },
});
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: relative;
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: inherit;
  margin: inherit;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.jump-to-content {
  position: absolute !important;
  top: 0;
  display: table;
  background: $oker-black;
  font-size: 18px;
  padding: 20px !important;
  white-space: nowrap;
  z-index: 9999;
  color: $oker-white;
  text-decoration: none;
}
</style>
