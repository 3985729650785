<template>
  <ul class="brand-list" v-if="!isLoading">
    <Brand v-for="brand in brands" :key="brand.name" :brand="brand" />
  </ul>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import Brand from "@/components/Brand.vue";

export default defineComponent({
  name: "BrandList",
  setup() {
    const isLoading = inject("isLoading");
    const brands = inject("brands");

    return {
      isLoading,
      brands,
    };
  },
  components: {
    Brand,
  },
});
</script>

<style lang="scss" scoped>
.brand-list {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
