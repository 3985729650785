
import { defineComponent } from "vue";
import OpeningHours from "@/components/OpeningHoursList.vue";
import Treatments from "@/components/TreatmentsList.vue";
import Block from "@/components/Block.vue";
import Brands from "@/components/BrandList.vue";
import Map from "@/components/Map.vue";
import Seo from "@/components/Seo.vue";
import BookOnlineButton from "@/components/BookOnlineButton.vue";
import company from "@/data/company";
import Page from "@/components/Page.vue";
import Intro from "@/components/Intro.vue";

export default defineComponent({
  name: "FrontPage",
  setup() {
    const introText = `Hos oss er du garantert godt håndverk og
          god kvalitet. Vi ønsker at du som kunde skal få en god opplevelse og
          veiledning. Vi tilbyr alle behandlinger innenfor vårt fag. Velkommen
          til oss, på Nittedal Stasjon.`;

    return { company, introText };
  },
  components: {
    Page,
    Intro,
    Brands,
    BookOnlineButton,
    Treatments,
    OpeningHours,
    Block,
    Map,
    Seo,
  },
});
