<template>
  <component :is="type" :class="classes" :to="to" :href="to">
    <slot name="icon" />
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "Button",
  props: {
    primary: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    to: {
      type: String,
      required: false,
    },
  },
  setup(props, { slots }) {
    const classes = computed(() => {
      return {
        button: true,
        "button--primary": props.primary,
        "button--icon": slots.icon,
      };
    });

    return { classes };
  },
  components: {
    RouterLink,
  },
});
</script>

<style lang="scss" scoped>
.button {
  padding: 7px 15px;
  border: 2px solid $oker-blue;
  box-shadow: 0 2px 3px 0px rgba($oker-black, 0.3);
  color: $oker-grey;
  background: $oker-white;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  width: auto;
  max-width: 350px;
  border-radius: 3px;
  display: inline-block;
  transition: background 0.1s ease-in-out, background 0.1s ease-in-out;
  letter-spacing: 1px;
  white-space: nowrap;
  outline: none;
  font-weight: 500;

  &:active {
    position: relative;
    top: 1px;
    box-shadow: none;
  }

  &:focus {
    @include focus-indicator;
  }

  &:hover {
    background: $oker-blue;
    color: $oker-white;
  }
}

.button--primary {
  background: $oker-blue;
  color: $oker-white;

  &:hover {
    background: #d68c0f;
    border-color: #d68c0f;
  }
}

.button > :deep(svg) {
  position: relative;
  left: -5px;
  margin: -1px 0;
  max-height: 25px;
  width: 25px;
}

.button + .button {
  margin-left: 20px;
}
</style>
