
import { defineComponent } from "vue";
import SteinarHovlandLogo from "@/assets/SteinarHovland-Logo-Dark.svg";

export default defineComponent({
  name: "MadeBy",
  components: {
    SteinarHovlandLogo,
  },
});
