import brands from "./brands";
import hairdressers from "./hairdressers";
import infoMesssage from "./infoMessage";
import openingHours from "./openingHours";
import treatments from "./treatments";

const queries = [brands, hairdressers, infoMesssage, openingHours, treatments];

export default `{
  ${queries.join(",")}
}`;
