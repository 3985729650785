<template>
  <li class="brand">
    <a
      class="brand-link"
      rel="noopener nofollow"
      :href="brand.url"
      :title="brand.name"
    >
      <img class="brand-logo" :src="brand.logo" :alt="`${brand.name} logo`" />
    </a>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Brand } from "@/types/brand";

export default defineComponent({
  name: "Brand",
  props: {
    brand: {
      type: Object as PropType<Brand>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.brand-logo {
  max-width: 100px;
  max-height: 60px;
  width: auto;
  height: auto;
  transition: opacity 0.15s ease-in-out;
}

.brand {
  list-style: none;
  text-align: center;
  width: 100px;

  margin: 20px;
}

.brand-link {
  transition: opacity 0.15s ease-in-out;
  opacity: 0.5;

  &:hover,
  &:focus {
    opacity: 1;
  }
}
</style>
