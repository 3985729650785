
import { defineComponent, PropType } from "vue";
import { Hairdresser } from "@/types/hairdresser";

export default defineComponent({
  name: "Hairdresser",
  props: {
    hairdresser: {
      type: Object as PropType<Hairdresser>,
    },
  },
});
