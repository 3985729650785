
import { defineComponent, PropType, computed } from "vue";
import { OpeningHour } from "@/types/openinghours";

export default defineComponent({
  name: "OpeningHour",
  props: {
    today: {
      type: Boolean,
      required: true,
    },
    openingHour: {
      type: Object as PropType<OpeningHour>,
      required: true,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        "opening-hours": true,
        "opening-hours--today": props.today,
      };
    });

    return { classes };
  },
});
