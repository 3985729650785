
import { defineComponent, computed } from "vue";
import OkerLogo from "@/assets/oker-logo.svg";

export default defineComponent({
  name: "Logo",
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        logo: true,
        "logo--inverted": props.inverted,
      };
    });

    return { classes };
  },
  components: {
    OkerLogo,
  },
});
