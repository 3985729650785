export const http = (query: string): Promise<any> =>
  fetch(`${process.env.VUE_APP_API}/v1/graphql/production/default`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  });
