
import { defineComponent } from "vue";
import Intro from "@/components/Intro.vue";
import Page from "@/components/Page.vue";
import Seo from "@/components/Seo.vue";
import Treatments from "@/components/TreatmentsList.vue";
import CallToAction from "@/components/CallToAction.vue";

export default defineComponent({
  name: "TreatmentsPage",
  setup() {
    const pageTitle = process.env.VUE_APP_TITLE;
    const introText = `Alle våre behandlinger utføres av profesjonelle frisører med nødvendig
          kunnskap til avtalt pris. Skulle du ikke finne det du ønsker i listen
          nedenfor ta kontakt med oss. Alle våre behandlinger inkluderer kur og
          deilig hodebunnsmassasje. Husk at alle våre priser er “fra” priser. Vi
          tar ekstra betalt for produktbruk som overstiger det som er kalkulert
          i prislisten. Ta kontakt med oss for mer informasjon!`;

    return { pageTitle, introText };
  },
  components: {
    Page,
    Intro,
    Treatments,
    CallToAction,
    Seo,
  },
});
