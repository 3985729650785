<template>
  <Seo
    title="Bestill time - Oker Frisør Nittedal"
    canonical="https://www.okerhar.no/bestill-time"
    description="Hos oss er du garantert godt håndverk og god kvalitet. Bestill din time i dag!"
  />
  <Page>
    <Intro color="blue" title="Bestill time" :intro="introText" />
    <div class="wrapper">
      <Button primary type="a" to="https://www.ledigtime.no/oker/step1">
        <template #icon>
          <CalendarIcon class="icon" />
        </template>
        Bestill time på nett
      </Button>
      <div class="or">eller</div>
      <Button type="a" :to="`tel:+47 ${Company.phone}`">
        <template #icon>
          <PhoneIcon class="icon" />
        </template>
        Ring {{ Company.phone }}
      </Button>
    </div>
  </Page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Page from "@/components/Page.vue";
import Intro from "@/components/Intro.vue";
import Seo from "@/components/Seo.vue";
import Button from "@/components/Button.vue";
import Company from "@/data/company";
import CalendarIcon from "@/assets/icons/calendar.svg";
import PhoneIcon from "@/assets/icons/phone.svg";

export default defineComponent({
  name: "BookAppointmentPage",
  setup() {
    const introText = `Alle våre behandlinger utføres av profesjonelle frisører med nødvendig kunnskap til avtalt pris. Velkommen til oss, på Nittedal Stasjon.`;

    return { introText, Company };
  },
  components: {
    PhoneIcon,
    CalendarIcon,
    Button,
    Intro,
    Page,
    Seo,
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  @include slanted;
  background: #fff;
  text-align: center;
}

.or {
  margin: 10px;
}

.button {
  width: 100%;
}
</style>
