
import { defineComponent, inject } from "vue";
import Brand from "@/components/Brand.vue";

export default defineComponent({
  name: "BrandList",
  setup() {
    const isLoading = inject("isLoading");
    const brands = inject("brands");

    return {
      isLoading,
      brands,
    };
  },
  components: {
    Brand,
  },
});
