import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import TreatmentsPage from "../pages/TreatmentsPage.vue";
import AboutPage from "../pages/AboutPage.vue";
import FrontPage from "../pages/FrontPage.vue";
import BookAppointmentPage from "../pages/BookAppointmentPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Forsiden",
    component: FrontPage,
  },
  {
    path: "/om-salongen",
    name: "Om Salongen",
    component: AboutPage,
  },
  {
    path: "/behandlinger",
    name: "Behandlinger",
    component: TreatmentsPage,
  },
  {
    path: "/bestill-time",
    name: "Bestill time",
    component: BookAppointmentPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
