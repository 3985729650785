<template>
  <OkerLogo :class="classes" />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import OkerLogo from "@/assets/oker-logo.svg";

export default defineComponent({
  name: "Logo",
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        logo: true,
        "logo--inverted": props.inverted,
      };
    });

    return { classes };
  },
  components: {
    OkerLogo,
  },
});
</script>

<style lang="scss" scoped>
.logo {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  width: 70px;
  @media (min-width: 600px) {
    width: 105px;
  }
}

.logo--inverted {
  color: $oker-white;
}
</style>
