
import { defineComponent } from "vue";
import Page from "@/components/Page.vue";
import Intro from "@/components/Intro.vue";
import Seo from "@/components/Seo.vue";
import Button from "@/components/Button.vue";
import Company from "@/data/company";
import CalendarIcon from "@/assets/icons/calendar.svg";
import PhoneIcon from "@/assets/icons/phone.svg";

export default defineComponent({
  name: "BookAppointmentPage",
  setup() {
    const introText = `Alle våre behandlinger utføres av profesjonelle frisører med nødvendig kunnskap til avtalt pris. Velkommen til oss, på Nittedal Stasjon.`;

    return { introText, Company };
  },
  components: {
    PhoneIcon,
    CalendarIcon,
    Button,
    Intro,
    Page,
    Seo,
  },
});
