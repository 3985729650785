<template>
  <div class="madeby">
    <span>Nettsiden er laget av</span>
    <a
      class="madeby-link"
      href="https://www.steinarhovland.no"
      aria-label="Steinar Hovland"
    >
      <SteinarHovlandLogo class="steinar-hovland-logo" alt="Steinar Hovland" />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SteinarHovlandLogo from "@/assets/SteinarHovland-Logo-Dark.svg";

export default defineComponent({
  name: "MadeBy",
  components: {
    SteinarHovlandLogo,
  },
});
</script>

<style lang="scss" scoped>
.madeby {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: -40px;
  opacity: 0.5;
  font-size: 12px;
  height: 30px;
  transition: opacity 0.1s ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.madeby-link {
  display: inline-block;
  margin-left: 5px;
}

.steinar-hovland-logo {
  height: auto;
  width: 150px;
  display: block;
}
</style>
