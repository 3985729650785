<template>
  <Banner :content="infoMessage.infoText" v-if="infoMessage.active" />
  <router-view />
</template>
<script>
import { defineComponent, provide, ref } from "vue";
import Banner from "@/components/Banner.vue";
import { http } from "@/services/http";
import mainQuery from "@/services/queries/main";
import {
  transformBrands,
  transformHairdressers,
  transformOpeningHours,
  transformTreatments,
} from "@/services/transformers/transformers";

export default defineComponent({
  setup() {
    const isLoading = ref(true);
    const hasError = ref(false);
    const treatments = ref([]);
    const hairdressers = ref([]);
    const brands = ref([]);
    const openingHours = ref([]);
    const infoMessage = ref({
      active: false,
      infoText: "",
    });

    http(mainQuery)
      .then(({ data }) => {
        infoMessage.value = data.allInfoMessage[0];

        hairdressers.value = transformHairdressers(
          data.allHairdressers[0].hairdresserlist
        );
        treatments.value = transformTreatments(
          data.allTreatments[0].treatmentlist
        );
        brands.value = transformBrands(data.allBrands);
        openingHours.value = transformOpeningHours(data.allOpeninghours);
      })
      .catch(() => {
        hasError.value = true;
      })
      .finally(() => {
        isLoading.value = false;
      });

    provide("isLoading", isLoading);
    provide("treatments", treatments);
    provide("openingHours", openingHours);
    provide("hairdressers", hairdressers);
    provide("infoMessage", infoMessage);
    provide("brands", brands);

    return { infoMessage };
  },

  components: { Banner },
});
</script>
