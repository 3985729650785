<template>
  <ol v-if="!isLoading">
    <OpeningHour
      v-for="openingHour in openingHours"
      :key="openingHour.day"
      :openingHour="openingHour"
      :today="activeDay === openingHour.id"
    />
  </ol>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OpeningHour from "@/components/OpeningHour.vue";
import { OpeningHour as OpeningHourType } from "@/types/openinghours";

interface OpeningHours {
  value: OpeningHourType[];
}

export default defineComponent({
  name: "OpeningHoursList",
  setup() {
    const isLoading = inject("isLoading");
    const openingHours: OpeningHours = inject("openingHours") || {
      value: [],
    };
    const activeDay = new Date().getDay();

    return {
      isLoading,
      activeDay,
      openingHours,
    };
  },
  components: {
    OpeningHour,
  },
});
</script>

<style lang="scss" scoped>
ol {
  padding: 0;
  margin: 0;
}
</style>
