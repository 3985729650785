<template>
  <div class="cta">
    <span class="cta-tagline">{{ title }}</span>
    <BookOnlineButton />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BookOnlineButton from "@/components/BookOnlineButton.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Bli bedre kjent med oss",
    },
    cta: {
      type: String,
      default: "Bestill time",
    },
  },
  components: {
    BookOnlineButton,
  },
});
</script>

<style lang="scss" scoped>
.cta {
  text-align: center;

  button {
    margin: 10px auto 0 auto;
  }
}

.cta-tagline {
  display: block;
  margin-bottom: 15px;
  font-weight: 400;
}
</style>
