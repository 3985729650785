<template>
  <div class="banner">
    <p>
      <InfoIcon class="banner-info-icon" />
      {{ content }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InfoIcon from "@/assets/icons/info.svg";

export default defineComponent({
  props: {
    content: {
      type: String,
      required: false,
    },
  },
  components: {
    InfoIcon,
  },
});
</script>

<style lang="scss" scoped>
.banner {
  padding: 40px;
  background: #eea62d;
  color: #1c1c1c;
}

.banner-info-icon {
  width: 30px;
  left: 0;
  top: -4px;
  position: absolute;
}

p {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding-left: 40px;
}
</style>
