<template>
  <iframe
    class="map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d274.6945953782932!2d10.86500709321157!3d60.05828291199439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4641772712bbe463%3A0x27e23958f768bcd4!2sOker%20AS!5e0!3m2!1sen!2sno!4v1617451417740!5m2!1sen!2sno"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
  ></iframe>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Map",
});
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 400px;
}
</style>
