
import { defineComponent } from "vue";
import company from "@/data/company";
import FacebookIcon from "@/assets/icons/facebook.svg";
import InstagramIcon from "@/assets/icons/instagram.svg";
import MadeBy from "@/components/MadeBy.vue";

export default defineComponent({
  name: "Footer",
  setup() {
    return { company };
  },
  components: {
    InstagramIcon,
    FacebookIcon,
    MadeBy,
  },
});
