<template>
  <Block title="Våre frisører">
    <ul class="hairdressers" v-if="!isLoading">
      <Hairdresser
        v-for="hairdresser in hairdressers"
        :key="hairdresser.id"
        :hairdresser="hairdresser"
      />
    </ul>
  </Block>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Hairdresser as HairdresserType } from "@/types/hairdresser";
import Block from "@/components/Block.vue";
import Hairdresser from "@/components/Hairdresser.vue";

interface Hairdressers {
  value: HairdresserType[];
}

export default defineComponent({
  setup() {
    const isLoading = inject("isLoading");
    const hairdressers: Hairdressers = inject("hairdressers") || {
      value: [],
    };
    return {
      isLoading,
      hairdressers,
    };
  },
  components: {
    Block,
    Hairdresser,
  },
});
</script>

<style lang="scss" scoped>
.hairdressers {
  padding: 0;
}
</style>
