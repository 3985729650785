export default `
allHairdressers(sort: [{ _createdAt: DESC }]) {
  hairdresserlist {
    name,
    description,
    portrait {
      asset {
        url
      }
    }
  }
}`;
